@import 'includes/variables';
@import 'includes/mixins';

.cta_card_container {
	.card {
		display: block;
		height: 100%;
	}
	&.csicon_align_center {
		.csicon_container {
			text-align: center;
		}
	}
	&.csicon_align_left,
	&.csicon_align_right {
		.card_inner {
			@include flexbox( 'row' );
		}
		.content_container {
			flex: 1;
		}
	}
	&.csicon_align_right {
		.content_container {
			order: 1;
		}
		.csicon_container {
			order: 2;
		}
	}
	.csicon_container {
		text-align: center;
	}
	.csicon_container_inner {
		display: inline-block;
	}
	.card_content {
		p:last_of_type {
			margin_bottom: 0;
		}
	}
}

.image-block-container {
	&.static-border,
	&.dynamic-border {
		.image-block-link-inner {
			border: 1px solid #fff;
			padding: 26px;
		}
	}
}

.image-block {
	@include flexbox();
	background-position: center center;
	background-size: cover;
	.image-block-link {
		flex: 1;
		display: block;
		position: relative;
		padding: 26px;
		overflow: hidden;
		transition: all .5s ease;
		.image-block-heading {
			transition: color .5s ease;
		}
		&:before {
			content: "";
		    z-index: 0;
		    position: absolute;
		    left: 0;
		    right: 0;
		    top: 0;
		    bottom: 0;
		    opacity: 0;
		    transition: opacity .5s ease;
		}
		&:hover,
		&:focus {
			&:before {
				opacity: 1;
			}
		}
		.image-block-link-inner {
			@include flexbox();
			justify-content: center;
			align-items: center;
			height: 100%;
			width: 100%;
			position: relative;
		}
		.image-block-line {
			position: absolute;
			transition: all ease 1s;
			background-color: #c93c91;
			&.line-top {
				height: 1px;
			    left: -1px;
			    top: -1px;
			    width: 10%;
			}
			&.line-right {
				height: 10%;
				right: -1px;
				top: -1px;
				width: 1px;
			}
			&.line-bottom {
				bottom: -1px;
			    height: 1px;
			    right: -1px;
			    width: 10%;
			}
			&.line-left {
				bottom: -1px;
				height: 10%;
				left: -1px;
				width: 1px;
			}
		}
		&:hover,
		&:focus {
			.line-top {
				width: calc(100% + 1px) !important;
			}
			.line-right {
				height: calc(100% + 1px) !important;
			}
			.line-bottom {
				width: calc(100% + 1px) !important;
			}
			.line-left {
				height: calc(100% + 1px) !important;
			}
		}
	}
	.image-block-heading,
	.image-block-subheading {
		display: block;
		word-break: break-word;
	}
	.image-block-content {
		width: 100%;
		position: relative;
		z-index: 60;
	}
}

.archive,
.blog,
.search {
	.entry {
		display: flex;
		flex-direction : column;
		gap: 20px;
		@include breakpoint( 'sm' ) {
			flex-direction: row;
			.entry-content {
				flex: 1;
			}
			.entry-header {
				width: 30%;
				max-width: 300px;
			}
		}
		.entry-header {
			a.entry-image-link {
				display: block;
			}
			.entry-image {
				margin: 0;
				padding: 0;
			}
		}
		&:before,
		&:after {
			display: none;
		}
	}
	div:empty,
	footer:empty,
	header:empty {
		display: none;
	}

}