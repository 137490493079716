/* -----------------------------------------------------------------------------
 *                       __  ____            ____
 *                      /  |/  (_)  __      /  _/___  _____
 *                     / /|_/ / / |/_/_____ / // __ \/ ___/
 *                    / /  / / />  </_____// // / / (__  )
 *                   /_/  /_/_/_/|_|     /___/_/ /_/____/
 *
 * ---------------------------------------------------------------------------*/
 @mixin clearfix {
    &:after, &:before {
        content: "";
        display: table;
    }
    &:after {
        clear: both;
    }
}
@mixin breakpoint( $break ) {
    @if map-has-key( $breakpoints, $break ) {
        @media ( min-width: #{ map-get( $breakpoints, $break ) } ) {
            @content;
        }
    }
    @else {
        @media ( $break ) {
            @content;
        }
    }
}

@mixin image-replacement( $image ) {
        display: block;
        background-image: url( $image );
        background-repeat: no-repeat;
        text-indent: 100%;
        white-space: nowrap;
        overflow: hidden;
    }
@mixin flexbox( $direction:'' ) {
    // display: -webkit-box;
    // display: -webkit-flex;
    // display: -ms-flexbox;
    display: flex;
    @if( $direction == 'row' ) {
        // -webkit-box-orient: horizontal;
        // -webkit-box-direction: normal;
        // -webkit-flex-direction: row;
        // -ms-flex-direction: row;
        flex-direction: row;
    }
    @if( $direction == 'column' ) {
        // -webkit-box-orient: vertical;
        // -webkit-box-direction: normal;
        // -webkit-flex-direction: column;
        // -ms-flex-direction: column;
        flex-direction: column;
    }
}
@mixin invisible() {
    clip: rect(0px, 0px, 0px, 0px);
    -webkit-clip-path: rect( 0px 0px, 0px 0px, 0px 0px, 0px 0px);
    clip-path: rect( 0px 0px, 0px 0px, 0px 0px, 0px 0px);
    position: absolute !important;
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    overflow: hidden;
    font-size: 0;
}